import * as yup from "yup";

const emailDomainPattern =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|co|net|org|edu|gov)$/;
export const validateLogin = yup.object().shape({
  email: yup
    .string()
    .email("El formato del correo electrónico no es válido")
    .matches(
      emailDomainPattern,
      "El correo electrónico debe terminar en .com, .co, .net, .org, .edu, o .gov"
    )
    .required("El correo electrónico es requerido")
    .transform((value) => value.toLowerCase()),
  password: yup
    .string()
    .required("La contraseña es requerida")
    .min(8, "La contraseña debe tener al menos 8 caracteres")
    .matches(
      /[A-Z]/,
      "La contraseña debe contener al menos una letra mayúscula"
    )
    .matches(
      /[a-z]/,
      "La contraseña debe contener al menos una letra minúscula"
    )
    .matches(/[0-9]/, "La contraseña debe contener al menos un número")
    .matches(
      /[\W_]/,
      "La contraseña debe contener al menos un carácter especial: .#?!@$%^&*-"
    ),
});

export const validateResetPassword = yup.object().shape({
  password: yup
    .string()
    .required("La contraseña es requerida")
    .min(8, "La contraseña debe tener al menos 8 caracteres")
    .matches(
      /[A-Z]/,
      "La contraseña debe contener al menos una letra mayúscula"
    )
    .matches(/[0-9]/, "La contraseña debe contener al menos un número")
    .matches(
      /[\W_]/,
      "La contraseña debe contener al menos un carácter especial: .#?!@$%^&*-"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Las contraseñas deben coincidir")
    .required("La confirmación de la contraseña es requerida"),
});

export const validateResetPassRequest = yup.object().shape({
  recovery: yup.string().required("Debes seleccionar una opción"),
  ind: yup.string().test("ind-required", "Requerido", function (value) {
    const { recovery } = this.parent;
    if (recovery === "email") {
      return true;
    }
    return !!value;
  }),

  phone: yup
    .string()
    .test("phone-required", "Número de teléfono inválido", function (value) {
      const { recovery, ind } = this.parent;
      if (recovery === "email") {
        return true;
      }
      if (!value) {
        return this.createError({ message: "Número de teléfono requerido" });
      }
      if (ind === "1") {
        return value && value.length === 8;
      } else {
        return value && value.length >= 8;
      }
      return true;
    }),

  email: yup
    .string()
    .transform((value) => value.toLowerCase())
    .test("email-required", function (value) {
      const { recovery } = this.parent;
      if (recovery === "phone") {
        return true;
      }
      if (!value) {
        return this.createError({ message: "Correo electrónico requerido" });
      }
      if (!yup.string().email().isValidSync(value)) {
        return this.createError({
          message: "Ingrese un correo electrónico válido",
        });
      }
      if (!emailDomainPattern.test(value)) {
        return this.createError({
          message:
            "El correo electrónico debe terminar en .com, .co, .net, .org, .edu, o .gov",
        });
      }
      return true;
    }),
});

export const validateOtp = yup.object().shape({
  code1: yup.string().required("Campo requerido"),
  code2: yup.string().required("Campo requerido"),
  code3: yup.string().required("Campo requerido"),
  code4: yup.string().required("Campo requerido"),
  code5: yup.string().required("Campo requerido"),
});

export const validateRegister = yup.object().shape({
  name: yup
    .string()
    .required("El nombre es requerido")
    .max(150, "Máximo 150 caracteres"),
  lastname: yup
    .string()
    .required("Los apellidos son requeridos")
    .max(150, "Máximo 150 caracteres"),
  ind: yup.string().required("Requerido"),
  phone: yup
    .string()
    .required("El celular es requerido")
    .test("phone-length", "Número de teléfono inválido", function (value) {
      const { ind } = this.parent;
      if (ind === "1") {
        return value && value.length === 8;
      } else {
        return value && value.length >= 8;
      }
    }),
  email: yup
    .string()
    .transform((value) => value.toLowerCase())
    .email("Dirección de correo electrónico inválida")
    .matches(
      emailDomainPattern,
      "El correo electrónico debe terminar en .com, .co, .net, .org, .edu, o .gov"
    )
    .required("El correo electrónico es requerido"),
  emailConfirm: yup
    .string()
    .transform((value) => value.toLowerCase())
    .oneOf(
      [yup.ref("email"), null],
      "Los correos electrónicos ingresados no coinciden."
    )
    .required("La confirmación del correo es requerida"),
  password: yup
    .string()
    .required("La contraseña es requerida")
    .min(8, "La contraseña debe tener al menos 8 caracteres")
    .matches(
      /[A-Z]/,
      "La contraseña debe contener al menos una letra mayúscula"
    )
    .matches(/[0-9]/, "La contraseña debe contener al menos un número")
    .matches(
      /[\W_]/,
      "La contraseña debe contener al menos un carácter especial: .#?!@$%^&*-"
    ),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Las contraseñas deben coincidir")
    .required("La confirmación de la contraseña es requerida"),
  code: yup.string().notRequired(),
});

export const validateContactUs = yup.object().shape({
  name: yup
    .string()
    .required("El nombre es requerido")
    .max(150, "Máximo 150 caracteres"),
  lastname: yup
    .string()
    .required("Los apellidos son requeridos")
    .max(150, "Máximo 150 caracteres"),
  ind: yup.string().required("Requerido"),
  phone: yup
    .string()
    .required("El celular es requerido")
    .test("phone-length", "Número de teléfono inválido", function (value) {
      const { ind } = this.parent;
      if (ind === "1") {
        return value && value.length === 8;
      } else {
        return value && value.length >= 8;
      }
    }),
  email: yup
    .string()
    .max(150, "Máximo 150 caracteres")
    .email("Dirección de correo electrónico inválida")
    .matches(
      emailDomainPattern,
      "El correo electrónico debe terminar en .com, .co, .net, .org, .edu, o .gov"
    )
    .required("El correo electrónico es requerido")
    .transform((value) => value.toLowerCase()),
  company: yup.string().max(150, "Máximo 150 caracteres"),
  message: yup.string().max(500, "Máximo 500 caracteres"),
});

export const validateProfileInfoStep1 = yup.object().shape({
  ind: yup.string().required("Requerido"),
  phone: yup
    .string()
    .required("El celular es requerido")
    .test("phone-length", "Número de teléfono inválido", function (value) {
      const { ind } = this.parent;
      if (ind === "1") {
        return value && value.length === 8;
      } else {
        return value && value.length >= 8;
      }
    }),
  email: yup
    .string()
    .transform((value) => value.toLowerCase())
    .email("Dirección de correo electrónico inválida")
    .matches(
      emailDomainPattern,
      "El correo electrónico debe terminar en .com, .co, .net, .org, .edu, o .gov"
    )
    .required("El correo electrónico es requerido"),
  department: yup.string().required("El departamento es requerido"),
  municipality: yup.string().required("El municipio es requerido"),
  address: yup.string().required("La dirección es requerida"),
  instruction: yup.string().nullable().notRequired(),
});

export const validateProfileInfoStep2 = yup.object().shape({
  name: yup
    .string()
    .required("El nombre es requerido")
    .max(150, "Máximo 150 caracteres"),
  lastName: yup
    .string()
    .required("Los apellidos son requeridos")
    .max(150, "Máximo 150 caracteres"),
  maritalStatus: yup.string().required("El Estado Civil es requerido"),
  gender: yup.string().required("El género es requerido"),
  birthdate: yup.date().required("La fecha de nacimiento es requerida"),
  role: yup.string().required("El rol de tu casa es requerido"),
});

export const validateEditUserProfile = yup.object().shape({
  ...validateProfileInfoStep1.fields,
  ...validateProfileInfoStep2.fields,
});
