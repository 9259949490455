import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "../../../components/Card";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import { useQueryClient } from "react-query";
import toast from "react-hot-toast";
import { getLabelById, getUserInfo } from "../../../utilities/helpers";
import { theme } from "../../../styles/theme";
import { Text, Col, Div, Row } from "../../../styles/Common";
import { Input } from "../../../components/Input";
import { Select } from "../../../components/Select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  GoogleMap,
  MarkerF,
  useLoadScript,
  Autocomplete,
} from "@react-google-maps/api";
import { PinIcon, Instagram } from "../../../assets/icons";
import { DatePicker } from "../../../components/DatePicker";
import { Radio } from "../../../components/Radio";
import { Button } from "../../../components/Button";
import { Tag } from "../../../components/Tag";
import { CopywriteFooter } from "../../../components/CopywriteFooter";
import mobile from "../../../assets/images/Mobile.png";
import useGetPhoneCode from "../../../core/hooks/useGetPhoneCode";
import useGetDepartmentList from "../../../core/hooks/useGetDepartmentList";
import useGetCityList from "../../../core/hooks/useGetCityList";
import useGetMaritalStatus from "../../../core/hooks/useGetMaritalStatus";
import useGetGender from "../../../core/hooks/useGetGender";
import useGetRole from "../../../core/hooks/useGetRole";
import useGetIncomeRange from "../../../core/hooks/useGetIncomeRange";
import useGetNumberChildren from "../../../core/hooks/useGetNumberChildren";
import useGetPhysicalActivities from "../../../core/hooks/useGetPhysicalActivities";
import useGetPets from "../../../core/hooks/useGetPets";
import useGetHobbies from "../../../core/hooks/useGetHobbies";
import useGetProfessions from "../../../core/hooks/useGetProfessions";
import useGetUserProfile from "../../ProfileInfo/hooks/useGetUserProfile";
import ModalOTPMyAccount from "./ModalOTPMyAccount";
import { setVerificationTokens } from "../../../core/store/verificationCodes";
import useSendEmailOtp from "../../Login/hooks/useSendEmailOtp";
import useSendSmsOtp from "../../Login/hooks/useSendSmsOtp";
import ModalWarningEdit from "./ModalWarningEdit";
import useEditUserProfile from "../hooks/useEditUserProfile";
import { validateEditUserProfile } from "../../../utilities/validations";
import useValidateUserExistByEmail from "../hooks/useValidateUserExistByEmail";
import useValidateUserExistByPhoneNumber from "../hooks/useValidateUserExistByPhoneNumber";

dayjs.extend(weekday);
dayjs.extend(localeData);

export const EditMyAccount = ({ setEditMyAccount }) => {
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validateEditUserProfile),
  });

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const sendEmailOtp = useSendEmailOtp();
  const sendSmsOtp = useSendSmsOtp();
  const editUserProfile = useEditUserProfile();
  const tokenRequested = useSelector(
    (state) => state.verificationCodes?.verificationTokens
  );

  const validateUserExistByEmail = useValidateUserExistByEmail();
  const validateUserExistByPhoneNumber = useValidateUserExistByPhoneNumber();

  const emailForm = watch("email");
  const phoneForm = watch("phone");
  const watchedFields = watch(["department", "municipality"]);
  const selectedHobbies = watch("hobbies") || [];
  const selectedPets = watch("pets") || [];

  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const [coordinates, setCoordinates] = useState({
    lat: 14.6349149,
    lng: -90.5068824,
  });
  const [isVerifiedEmail, setIsVerifiedEmail] = useState(true);
  const [isVerifiedPhone, setIsVerifiedPhone] = useState(true);
  const [dataInitial, setDataInitial] = useState({
    email: "",
    phone: "",
  });
  const [showModalOTP, setShowModalOTP] = useState(false);
  const [showModalWarningEdit, setShowModalWarningEdit] = useState(false);
  const [selectedInput, setSelectedInput] = useState(null);
  const [confirmEditOtp, setConfirmEditOtp] = useState({
    phone: false,
    email: false,
  });
  const [form, setForm] = useState({});

  const disabledDate = (current) => {
    return (
      current && current.isAfter(dayjs().subtract(18, "years").endOf("day"))
    );
  };

  const handleMarkerDragEnd = async (event) => {
    const { lat, lng } = event.latLng.toJSON();
    setCoordinates({ lat, lng });

    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
    );
    const data = await response.json();
    if (data.results.length > 0) {
      setValue("maps", data.results[0].formatted_address);
      setValue("address", data.results[0].formatted_address);
    }
  };

  const updateCoordinates = async ({ lat, lng }) => {
    setCoordinates({ lat, lng });

    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
    );
    const data = await response.json();
    if (data.results.length > 0) {
      setValue("maps", data.results[0].formatted_address);
      setValue("address", data.results[0].formatted_address);
    }
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries: ["places"],
  });
  const [autocomplete, setAutocomplete] = useState(null);

  const onLoad = useCallback((autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  }, []);

  const onPlaceChanged = useCallback(() => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const newCoordinates = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        updateCoordinates(newCoordinates);
      }
    }
  }, [autocomplete, updateCoordinates]);

  const { nameid, unique_name } = getUserInfo();
  const getUserProfile = useGetUserProfile(nameid);
  const { data: dataUserProfile, isSuccess: isSuccessUserProfile } =
    getUserProfile;
  useEffect(() => {
    if (isSuccessUserProfile) {
      reset({
        name: dataUserProfile?.data?.result[0]?.name,
        lastName: dataUserProfile?.data?.result[0]?.lastName,
        ind: dataUserProfile?.data?.result[0]?.phoneNumberCode,
        phone: dataUserProfile?.data?.result[0]?.phoneNumber,
        email: dataUserProfile?.data?.result[0]?.email,
        department: dataUserProfile?.data?.result[0]?.departmentId,
        municipality: dataUserProfile?.data?.result[0]?.cityId,
        maps: dataUserProfile?.data?.result[0]?.exactAddress,
        address: dataUserProfile?.data?.result[0]?.address,
        instruction: dataUserProfile?.data?.result[0]?.instruction,
        maritalStatus: dataUserProfile?.data?.result[0]?.maritalStatusId,
        gender: dataUserProfile?.data?.result[0]?.genderId,
        birthdate: !!dataUserProfile?.data?.result[0].birthDate
          ? dayjs(dataUserProfile?.data?.result[0]?.birthDate)
          : null,
        role: dataUserProfile?.data?.result[0]?.roleHouseId,
        incomeRange: dataUserProfile?.data?.result[0]?.incomeRangeId,
        numberChildren: dataUserProfile?.data?.result[0]?.numberChildrenId,
        physicalActivities:
          dataUserProfile?.data?.result[0]?.frequencyActivitiesPhysicalId,
        profession: dataUserProfile?.data?.result[0]?.professionsId,
        instagram: dataUserProfile?.data?.result[0]?.instagram,
        isPregnant: dataUserProfile?.data?.result[0]?.isPregnant,
        isInterestedTechnology:
          dataUserProfile?.data?.result[0]?.isInterestedTechnology,
        householdRole: dataUserProfile?.data?.result[0]?.isBuyManagerHome,
        pets: dataUserProfile?.data?.result[0]?.petsId,
        hobbies: dataUserProfile?.data?.result[0]?.hobbiesId,
      });

      setDataInitial({
        email: dataUserProfile?.data?.result[0].email || "",
        phone: dataUserProfile?.data?.result[0].phoneNumber || null,
      });

      if (
        dataUserProfile?.data?.result[0]?.latitude &&
        dataUserProfile?.data?.result[0]?.longitude
      ) {
        setCoordinates({
          lat: Number(dataUserProfile?.data?.result[0]?.latitude),
          lng: Number(dataUserProfile?.data?.result[0]?.longitude),
        });
      }
    }
  }, [dataUserProfile, isSuccessUserProfile]);

  const initials =
    dataUserProfile?.data?.result[0]?.name &&
    dataUserProfile?.data?.result[0]?.lastName
      ? `${dataUserProfile?.data?.result[0]?.name.charAt(
          0
        )}${dataUserProfile?.data?.result[0]?.lastName.charAt(0)}`.toUpperCase()
      : "NN";

  useEffect(() => {
    if (emailForm !== dataInitial.email) {
      setIsVerifiedEmail(false);
    } else {
      setIsVerifiedEmail(true);
    }
    if (phoneForm !== dataInitial.phone) {
      setIsVerifiedPhone(false);
    } else {
      setIsVerifiedPhone(true);
    }
  }, [emailForm, phoneForm, dataInitial]);

  const getPhoneCode = useGetPhoneCode();
  const { data, isSuccess } = getPhoneCode;
  const [optionsCodes, setOptionsCodes] = useState([]);
  useEffect(() => {
    if (isSuccess) {
      setOptionsCodes(
        data?.data?.result.map((item) => ({
          label: item.code,
          value: item.id,
        }))
      );
    }
  }, [data, isSuccess]);

  const getDepartmentList = useGetDepartmentList();
  const { data: dataDepartments, isSuccess: isSuccessDepartments } =
    getDepartmentList;
  const [departmentOptions, setDepartmentOptions] = useState([]);
  useEffect(() => {
    if (isSuccessDepartments)
      setDepartmentOptions(
        dataDepartments?.data?.result.map((item) => ({
          label: item.departmentName,
          value: item.id,
        }))
      );
  }, [dataDepartments]);

  const getCityList = useGetCityList(watchedFields[0]);
  const { data: dataCity, isSuccess: isSuccessDataCity } = getCityList;
  const [cityOptions, setCityOptions] = useState([]);
  useEffect(() => {
    if (isSuccessDataCity)
      setCityOptions(
        dataCity?.data?.result.map((item) => ({
          label: item.cityName,
          value: item.id,
        }))
      );
  }, [dataCity]);

  const getMaritalStatus = useGetMaritalStatus();
  const { data: dataMaritalStatus, isSuccess: isSuccessMaritalStatus } =
    getMaritalStatus;
  const [optionsMaritalStatus, setOptionsMaritalStatus] = useState([]);
  useEffect(() => {
    if (isSuccessMaritalStatus) {
      setOptionsMaritalStatus(
        dataMaritalStatus?.data?.result.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      );
    }
  }, [dataMaritalStatus, isSuccessMaritalStatus]);

  const getGender = useGetGender();
  const { data: dataGender, isSuccess: isSuccessGender } = getGender;
  const [optionsGender, setOptionsGender] = useState([]);
  useEffect(() => {
    if (isSuccessGender) {
      setOptionsGender(
        dataGender?.data?.result.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      );
    }
  }, [dataGender, isSuccessGender]);

  const getRole = useGetRole();
  const { data: dataRole, isSuccess: isSuccessRole } = getRole;
  const [optionsRole, setOptionsRole] = useState([]);
  useEffect(() => {
    if (isSuccessRole) {
      setOptionsRole(
        dataRole?.data?.result.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      );
    }
  }, [dataRole, isSuccessRole]);

  const getIncomeRange = useGetIncomeRange();
  const { data: dataIncomeRange, isSuccess: isSuccessIncomeRange } =
    getIncomeRange;
  const [optionsIncomeRange, setOptionsIncomeRange] = useState([]);
  useEffect(() => {
    if (isSuccessIncomeRange) {
      setOptionsIncomeRange(
        dataIncomeRange?.data?.result.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      );
    }
  }, [dataIncomeRange, isSuccessIncomeRange]);

  const getNumberChildren = useGetNumberChildren();
  const { data: dataNumberChildren, isSuccess: isSuccessNumberChildren } =
    getNumberChildren;
  const [optionsNumberChildren, setOptionsNumberChildren] = useState([]);
  useEffect(() => {
    if (isSuccessNumberChildren) {
      setOptionsNumberChildren(
        dataNumberChildren?.data?.result.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      );
    }
  }, [dataNumberChildren, isSuccessNumberChildren]);

  const getPhysicalActivities = useGetPhysicalActivities();
  const {
    data: dataPhysicalActivities,
    isSuccess: isSuccessPhysicalActivities,
  } = getPhysicalActivities;
  const [optionsPhysicalActivities, setOptionsPhysicalActivities] = useState(
    []
  );
  useEffect(() => {
    if (isSuccessPhysicalActivities) {
      setOptionsPhysicalActivities(
        dataPhysicalActivities?.data?.result.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      );
    }
  }, [dataPhysicalActivities, isSuccessPhysicalActivities]);

  const getPets = useGetPets();
  const { data: dataPets, isSuccess: isSuccessPets } = getPets;
  const [optionsPets, setOptionsPets] = useState([]);
  useEffect(() => {
    if (isSuccessPets) {
      setOptionsPets(
        dataPets?.data?.result.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      );
    }
  }, [dataPets, isSuccessPets]);

  const getHobies = useGetHobbies();
  const { data: dataHobbies, isSuccess: isSuccessHobbies } = getHobies;
  const [optionsHobbies, setOptionsHobbies] = useState([]);
  useEffect(() => {
    if (isSuccessHobbies) {
      setOptionsHobbies(
        dataHobbies?.data?.result.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      );
    }
  }, [dataHobbies, isSuccessHobbies]);

  const getProfessions = useGetProfessions();
  const { data: dataProfessions, isSuccess: isSuccessProfessions } =
    getProfessions;
  const [optionsProfessions, setOptionsProfessions] = useState([]);
  useEffect(() => {
    if (isSuccessProfessions) {
      const groupedProfessions = _.groupBy(dataProfessions.data.result, "code");
      const formattedOptions = Object.keys(groupedProfessions).map((code) => ({
        label: code,
        options: groupedProfessions[code].map((item) => ({
          label: item.name,
          value: item.id,
        })),
      }));

      setOptionsProfessions(formattedOptions);
    }
  }, [dataProfessions, isSuccessProfessions]);

  useEffect(() => {
    const city = getLabelById(watchedFields[1], cityOptions);
    const department = getLabelById(watchedFields[0], departmentOptions);

    if (city && department) {
      const fetchCoordinates = async () => {
        const address = `${city}, ${department}`;
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address
          )}&key=${apiKey}`
        );
        const data = await response.json();
        if (data.results.length > 0) {
          const { lat, lng } = data.results[0].geometry.location;
          setCoordinates({ lat, lng });
        }
      };

      fetchCoordinates();
    }
  }, [
    watchedFields[0],
    watchedFields[1],
    apiKey,
    cityOptions,
    departmentOptions,
  ]);

  const handleClose = (removedTag, value) => {
    const currentValues = watch(value);
    const newHobbies = currentValues.filter((item) => item !== removedTag);

    setValue(value, newHobbies);
  };

  const handleCloseModal = () => {
    setShowModalOTP(false);
    setShowModalWarningEdit(false);
  };

  const handleSave = async (data) => {
    if (!isVerifiedEmail || !isVerifiedPhone) {
      const emailAction = !isVerifiedEmail ? sendEmailOtp : null;
      const phoneAction = !isVerifiedPhone ? sendSmsOtp : null;

      const emailData = !isVerifiedEmail
        ? { email: data.email, userName: unique_name, isPasswordReset: false }
        : null;
      const phoneData = !isVerifiedPhone
        ? {
            phoneNumber: `${getLabelById(Number(data.ind), optionsCodes)}${
              data.phone
            }`,
            isPasswordReset: false,
          }
        : null;

      const handleSuccess = (res, type) => {
        if (type === "email") {
          dispatch(
            setVerificationTokens({
              tokenEmail: res?.data?.result,
              tokenPhone: tokenRequested?.tokenPhone,
            })
          );
        } else if (type === "phone") {
          dispatch(
            setVerificationTokens({
              tokenEmail: tokenRequested?.tokenEmail,
              tokenPhone: res?.data?.result,
            })
          );
        }
      };

      const checkEmail = async () => {
        try {
          const res = await validateUserExistByEmail.mutateAsync({
            userId: Number(nameid),
            userEmail: data.email,
          });
          if (res?.data.result) {
            toast.error("Correo ya registrado");
            return false;
          }
          return true;
        } catch (err) {
          toast.error(err?.response?.data?.title || err?.response?.data);
          return false;
        }
      };

      const checkPhone = async () => {
        try {
          const res = await validateUserExistByPhoneNumber.mutateAsync({
            userId: Number(nameid),
            phoneNumber: data.phone,
          });
          if (res?.data.result) {
            toast.error("Teléfono ya registrado");
            return false;
          }
          return true;
        } catch (err) {
          toast.error(err?.response?.data?.title || err?.response?.data);
          return false;
        }
      };

      try {
        const [emailAvailable, phoneAvailable] = await Promise.all([
          checkEmail(),
          checkPhone(),
        ]);

        if (emailAvailable && phoneAvailable) {
          if (emailAction) {
            emailAction.reset();
            await emailAction.mutateAsync(emailData, {
              onSuccess: (res) => handleSuccess(res, "email"),
              onError: (err) => {
                toast.error(err?.response?.data?.title || err?.response?.data);
              },
            });
          }

          if (phoneAction) {
            phoneAction.reset();
            await phoneAction.mutateAsync(phoneData, {
              onSuccess: (res) => handleSuccess(res, "phone"),
              onError: (err) => {
                toast.error(
                  err?.response?.data?.title ||
                    err?.response?.data ||
                    err?.message
                );
              },
            });
          }
          setShowModalOTP(true);
          setForm({
            ...data,
            nameid: nameid,
            lat: String(coordinates.lat),
            lng: String(coordinates.lng),
          });
        }
      } catch (err) {
        toast.error("Hubo un error al verificar los datos. Intente de nuevo.");
      }
    } else {
      editUserProfile.reset();
      editUserProfile.mutate(
        {
          userId: Number(nameid),
          maritalStatusId: data.maritalStatus,
          genderId: data.gender,
          roleHouseId: data.role,
          cityId: Number(data.municipality),
          address: data.address,
          instruction: data.instruction,
          phoneNumber: data.phone,
          name: data.name,
          email: data.email,
          lastName: data.lastName,
          birthDate: data.birthdate,
          phoneCodeId: Number(data.ind),
          departmentId: Number(data.department),
          exactAddress: data.maps,
          latitude: String(coordinates.lat),
          longitude: String(coordinates.lng),
          profileImageUrl: null, //pending
          incomeRangeId: data.incomeRange,
          numberChildrenId: data.numberChildren,
          professionsId: data.profession,
          frequencyActivitiesPhysicalId: data.physicalActivities,
          isBuyManagerHome: data.householdRole,
          isPregnant: data.isPregnant,
          isInterestedTechnology: data.isInterestedTechnology,
          hobbiesId: data.hobbies ? data.hobbies.filter((item) => item) : null,
          petsId: data.pets ? data.pets.filter((item) => item) : null,
          instagram: data.instagram,
          //otherPet: null, //pending
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(["getUserProfile"]);
            toast.success("Cambios guardados correctamente");
            setEditMyAccount(false);
          },
          onError: (err) => {
            if (err?.response?.data?.errors) {
              const errorMessages = Object.values(err.response.data.errors)
                .flat()
                .join(", ");
              toast.error(errorMessages);
            }
          },
        }
      );
    }
  };

  return (
    <>
      <ModalOTPMyAccount
        showModalOTP={showModalOTP}
        handleCloseModal={handleCloseModal}
        form={form}
        isVerifiedEmail={isVerifiedEmail}
        isVerifiedPhone={isVerifiedPhone}
        optionsCodes={optionsCodes}
        setEditMyAccount={setEditMyAccount}
      />
      <ModalWarningEdit
        showModal={showModalWarningEdit}
        handleCloseModal={handleCloseModal}
        confirmEditOtp={confirmEditOtp}
        setConfirmEditOtp={setConfirmEditOtp}
        input={selectedInput}
      />
      <Card
        width={"610px"}
        height={"auto"}
        p="32.5px 37.5px 34px 37px"
        m="0 0 60px 0"
      >
        <Col align="center" justify="center">
          <Text
            size={theme.fonts.size.h4}
            weight={theme.fonts.weight.bold}
            color={theme.colors.white}
            mb="24px"
          >
            Edita tus datos
          </Text>
          <Div
            height="80px"
            width="80px"
            radius="50px"
            background={theme.colors.red}
            align="center"
            justify="center"
            m="0 0 8px 0"
          >
            <Text
              size={theme.fonts.size.h3}
              weight={theme.fonts.weight.bold}
              color={theme.colors.white}
            >
              {initials}
            </Text>
          </Div>
          <Text size={theme.fonts.size.sm} color={theme.colors.red} mb="32.5px">
            Cambiar foto
          </Text>
          <Row gap="19px" m="0 0 24.2px 0">
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input
                  label="Nombre"
                  colorLabel={theme.colors.gray200}
                  sizeLabel={theme.fonts.size.default}
                  border={theme.colors.white200}
                  width="258px"
                  height="45px"
                  background={theme.colors.blue100}
                  error={errors?.name}
                  helper={errors?.name?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <Input
                  label="Apellidos"
                  colorLabel={theme.colors.gray200}
                  sizeLabel={theme.fonts.size.default}
                  border={theme.colors.white200}
                  width="258px"
                  height="45px"
                  background={theme.colors.blue100}
                  error={errors?.lastName}
                  helper={errors?.lastName?.message}
                  {...field}
                />
              )}
            />
          </Row>
          <Row m="0 0 24.2px 0" width="100%">
            <Controller
              name="ind"
              control={control}
              render={({ field }) => (
                <Select
                  label="Indicativo"
                  color={theme.colors.gray200}
                  border={theme.colors.white200}
                  options={optionsCodes}
                  width="78.5px"
                  height="45px"
                  style={{ padding: "0px 2px" }}
                  background={theme.colors.blue100}
                  error={errors?.ind}
                  helper={errors?.ind?.message}
                  m="0 10px 0 0"
                  {...field}
                />
              )}
            />
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <Input
                  label={"Número de teléfono"}
                  colorLabel={theme.colors.gray200}
                  sizeLabel={theme.fonts.size.default}
                  width={"168px"}
                  error={errors.phone}
                  margin="0 18px 0 0"
                  helper={errors.phone ? errors.phone.message : ""}
                  onFocus={() => {
                    if (!confirmEditOtp?.phone) {
                      setSelectedInput("phone");
                      setShowModalWarningEdit(true);
                    }
                  }}
                  {...field}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  label={"Correo electrónico"}
                  colorLabel={theme.colors.gray200}
                  sizeLabel={theme.fonts.size.default}
                  width={"258px"}
                  error={errors.email}
                  helper={errors.email ? errors.email.message : ""}
                  onFocus={() => {
                    if (!confirmEditOtp?.email) {
                      setSelectedInput("email");
                      setShowModalWarningEdit(true);
                    }
                  }}
                  {...field}
                />
              )}
            />
          </Row>
          <Row gap="19px">
            <Controller
              name="department"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label={"Departamento"}
                  width={"258px"}
                  height={"45px"}
                  options={departmentOptions}
                  background={theme.colors.blue100}
                  error={errors.department}
                  helper={errors.department ? errors.department.message : ""}
                  onChange={(e) => {
                    field.onChange(e);
                    setValue("municipality", null);
                    queryClient.invalidateQueries(["getCityList", e]);
                  }}
                />
              )}
            />
            <Controller
              name="municipality"
              control={control}
              render={({ field }) => (
                <Select
                  label={"Municipio"}
                  width={"258px"}
                  height={"45px"}
                  options={cityOptions}
                  background={theme.colors.blue100}
                  error={errors.municipality}
                  helper={
                    errors.municipality ? errors.municipality.message : ""
                  }
                  {...field}
                />
              )}
            />
          </Row>
          <Row m="0 0 16px 0" align="left">
            <Div
              width="536px"
              height="194px"
              background={theme.colors.white}
              radius="22px"
              m="20px 0 0 0"
              style={{ overflow: "hidden" }}
            >
              {isLoaded && (
                <GoogleMap
                  mapContainerStyle={{ height: "194px", width: "536px" }}
                  center={
                    coordinates.lat !== 0 && coordinates.lng !== 0
                      ? coordinates
                      : { lat: 14.6349149, lng: -90.5068824 }
                  }
                  zoom={15}
                  options={{
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                    zoomControl: false,
                  }}
                  onClick={handleMarkerDragEnd}
                >
                  <Controller
                    name="maps"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        onLoad={onLoad}
                        onPlaceChanged={onPlaceChanged}
                      >
                        <Input
                          placeholder={"Ingresa tu dirección exacta"}
                          colorLabel={theme.colors.gray200}
                          sizeLabel={theme.fonts.size.default}
                          border={theme.colors.gray200}
                          style={{ padding: "0px 20px", margin: "7.5px 9.5px" }}
                          width={"468px"}
                          background={theme.colors.white}
                          error={errors.maps}
                          helper={errors.maps ? errors.maps.message : ""}
                          suffix={
                            <PinIcon
                              stroke={theme.colors.gray200}
                              width={"24px"}
                              height={"24px"}
                            />
                          }
                          {...field}
                        />
                      </Autocomplete>
                    )}
                  />
                  <MarkerF
                    position={coordinates}
                    draggable={true}
                    onDragEnd={handleMarkerDragEnd}
                  />
                </GoogleMap>
              )}
            </Div>
          </Row>
          <Col gap="8px" align="left">
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <Input
                  label={"Dirección"}
                  colorLabel={theme.colors.gray200}
                  sizeLabel={theme.fonts.size.default}
                  style={{ padding: "0px 20px" }}
                  width={"535.5px"}
                  background={theme.colors.blue100}
                  error={errors.address}
                  helper={errors.address ? errors.address.message : ""}
                  suffix={
                    <PinIcon
                      stroke={theme.colors.white}
                      width={"24px"}
                      height={"24px"}
                    />
                  }
                  {...field}
                />
              )}
            />
            <Text
              size={theme.fonts.size.sm}
              color={theme.colors.gray200}
              style={{ padding: "0 10px" }}
              mb="24px"
            >
              Ejem: Especificar zona, piso, apartamento o número. En caso de no
              encontrar tu dirección en el mapa, escribe tu dirección completa
              aquí.
            </Text>
          </Col>
          <Col align="left" gap="8px">
            <Controller
              name="instruction"
              control={control}
              render={({ field }) => (
                <Input
                  label={"Indicaciones"}
                  colorLabel={theme.colors.gray200}
                  sizeLabel={theme.fonts.size.default}
                  style={{ padding: "0px 20px" }}
                  width={"535px"}
                  background={theme.colors.blue100}
                  {...field}
                />
              )}
            />
            <Text
              size={theme.fonts.size.sm}
              color={theme.colors.gray200}
              style={{ padding: "0 10px" }}
              mb="24.7px"
            >
              Tú información de residencia es muy importante para que podamos
              enviarte la PulpeyBox a la puerta de tu casa.
            </Text>
          </Col>
          <Row gap="19px" m="0 0 24.4px 0">
            <Controller
              name="maritalStatus"
              control={control}
              render={({ field }) => (
                <Select
                  label="Estado Civil"
                  color={theme.colors.gray200}
                  border={theme.colors.white200}
                  width="258px"
                  height="45px"
                  background={theme.colors.blue100}
                  options={optionsMaritalStatus}
                  error={errors?.maritalStatus}
                  helper={errors?.maritalStatus?.message}
                  {...field}
                />
              )}
            />

            <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <Select
                  label="Género"
                  color={theme.colors.gray200}
                  border={theme.colors.white200}
                  width="258px"
                  height="45px"
                  background={theme.colors.blue100}
                  options={optionsGender}
                  error={errors?.gender}
                  helper={errors?.gender?.message}
                  {...field}
                />
              )}
            />
          </Row>
          <Row gap="19px" m="0 0 24px 0">
            <Controller
              name="birthdate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  label="Fecha de nacimiento"
                  placeholder={false}
                  color={theme.colors.gray200}
                  border={theme.colors.white200}
                  width="258px"
                  height="45px"
                  format="DD/MM/YYYY"
                  background={theme.colors.blue100}
                  error={errors?.birthdate}
                  helper={errors?.birthdate?.message}
                  disabledDate={disabledDate}
                  dateRender={(current) => {
                    const isDisabled = disabledDate(current);
                    const isSelected =
                      field.value && current.isSame(dayjs(field.value), "day");
                    return (
                      <div
                        style={{
                          display: isDisabled ? "none" : "block",
                          background: isSelected ? theme.colors.red : "none",
                        }}
                      >
                        {current.date()}
                      </div>
                    );
                  }}
                  {...field}
                />
              )}
            />
            <Controller
              name="role"
              control={control}
              render={({ field }) => (
                <Select
                  label="Rol de tu casa"
                  color={theme.colors.gray200}
                  border={theme.colors.white200}
                  width="258px"
                  height="45px"
                  background={theme.colors.blue100}
                  options={optionsRole}
                  error={errors?.role}
                  helper={errors?.role?.message}
                  {...field}
                />
              )}
            />
          </Row>

          <Row gap="19px" m="0 0 24px 0">
            <Controller
              name="incomeRange"
              control={control}
              render={({ field }) => (
                <Select
                  label="Rango de ingresos"
                  color={theme.colors.gray200}
                  border={theme.colors.white200}
                  width="258px"
                  height="45px"
                  background={theme.colors.blue100}
                  options={optionsIncomeRange}
                  {...field}
                />
              )}
            />
            <Controller
              name="numberChildren"
              control={control}
              render={({ field }) => (
                <Select
                  label="Cantidad de hijos"
                  color={theme.colors.gray200}
                  border={theme.colors.white200}
                  width="258px"
                  height="45px"
                  background={theme.colors.blue100}
                  options={optionsNumberChildren}
                  {...field}
                />
              )}
            />
          </Row>

          <Row gap="19px" m="0 0 24px 0">
            <Controller
              name="physicalActivities"
              control={control}
              render={({ field }) => (
                <Select
                  label="Frecuencia actividades físicas"
                  color={theme.colors.gray200}
                  border={theme.colors.white200}
                  width="258px"
                  height="45px"
                  background={theme.colors.blue100}
                  options={optionsPhysicalActivities}
                  {...field}
                />
              )}
            />

            <Controller
              name="profession"
              control={control}
              render={({ field }) => (
                <Select
                  label="Profesión u oficio"
                  color={theme.colors.gray200}
                  border={theme.colors.white200}
                  width="258px"
                  height="45px"
                  background={theme.colors.blue100}
                  options={optionsProfessions}
                  {...field}
                />
              )}
            />
          </Row>

          <Row m="0 0 23px 0" gap="20px" width="100%">
            <Controller
              name="instagram"
              control={control}
              render={({ field }) => (
                <Input
                  label="Perfil de Instagram"
                  sizeLabel={theme.fonts.size.default}
                  colorLabel={theme.colors.gray200}
                  color={theme.colors.gray200}
                  border={theme.colors.white200}
                  style={{ padding: "5px 12px" }}
                  width="259px"
                  height="45px"
                  background={theme.colors.blue100}
                  options={optionsProfessions}
                  suffix={
                    <Instagram
                      stroke={theme.colors.gray200}
                      width={"24px"}
                      height={"24px"}
                    />
                  }
                  {...field}
                />
              )}
            />
            <Col gap="4px">
              <Text color={theme.colors.gray200}>
                ¿Te encuentras embarazada?
              </Text>
              <Controller
                name="isPregnant"
                control={control}
                render={({ field }) => (
                  <Radio.Group {...field} value={field.value} gap="48px">
                    <Radio
                      value={true}
                      label="Si"
                      background={theme.colors.blue100}
                    />
                    <Radio
                      value={false}
                      label="No"
                      background={theme.colors.blue100}
                    />
                  </Radio.Group>
                )}
              />
            </Col>
          </Row>

          <Row m="0 0 23px 0" gap="20px" width="100%">
            <Col gap="4px">
              <Text color={theme.colors.gray200} align="left">
                {" "}
                ¿Te interesa la innovación tecnológica?
              </Text>
              <Controller
                name="isInterestedTechnology"
                control={control}
                render={({ field }) => (
                  <Radio.Group {...field} value={field.value} gap="48px">
                    <Radio
                      value={true}
                      label="Si"
                      background={theme.colors.blue100}
                    />
                    <Radio
                      value={false}
                      label="No"
                      background={theme.colors.blue100}
                    />
                  </Radio.Group>
                )}
              />
            </Col>

            <Col gap="4px">
              <Text color={theme.colors.gray200} align="left">
                ¿Tomas el rol de las compras del hogar?
              </Text>
              <Controller
                name="householdRole"
                control={control}
                render={({ field }) => (
                  <Radio.Group {...field} value={field.value} gap="48px">
                    <Radio
                      value={true}
                      label="Si"
                      background={theme.colors.blue100}
                    />
                    <Radio
                      value={false}
                      label="No"
                      background={theme.colors.blue100}
                    />
                  </Radio.Group>
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Controller
                name="pets"
                control={control}
                render={({ field }) => (
                  <Select
                    label="Mascotas"
                    mode="multiple"
                    color={theme.colors.gray200}
                    border={theme.colors.white200}
                    width="535px"
                    height="45px"
                    background={theme.colors.blue100}
                    options={optionsPets}
                    value={null}
                    tagRender={() => null}
                    {...field}
                  />
                )}
              />{" "}
              <Row style={{ flexWrap: "wrap" }} m="10.4px 0 32.5px 0">
                {selectedPets &&
                  selectedPets.length > 0 &&
                  selectedPets
                    .filter((item) => item)
                    .map((item, index) => (
                      <Tag
                        key={index}
                        closable
                        onClose={() => handleClose(item, "pets")}
                      >
                        {getLabelById(item, optionsPets)}
                      </Tag>
                    ))}
              </Row>{" "}
            </Col>
          </Row>
          <Row>
            <Col>
              <Controller
                name="hobbies"
                control={control}
                render={({ field }) => (
                  <Select
                    label="Hobbies"
                    mode="multiple"
                    color={theme.colors.gray200}
                    border={theme.colors.white200}
                    width="535px"
                    height="45px"
                    background={theme.colors.blue100}
                    options={optionsHobbies}
                    value={null}
                    tagRender={() => null}
                    {...field}
                  />
                )}
              />

              <Row style={{ flexWrap: "wrap" }} m="14.5px 0 59.7px 0">
                {selectedHobbies &&
                  selectedHobbies.length > 0 &&
                  selectedHobbies
                    .filter((item) => item)
                    .map((item, index) => (
                      <Tag
                        key={index}
                        closable
                        onClose={() => handleClose(item, "hobbies")}
                      >
                        {getLabelById(item, optionsHobbies)}
                      </Tag>
                    ))}
              </Row>
            </Col>
          </Row>

          <Col gap="28px">
            <form onSubmit={handleSubmit(handleSave)}>
              <Button
                width={"318px"}
                htmlType="submit"
                background={theme.colors.red}
                loading={
                  validateUserExistByEmail.isLoading ||
                  validateUserExistByPhoneNumber.isLoading ||
                  sendEmailOtp.isLoading ||
                  sendSmsOtp.isLoading ||
                  editUserProfile.isLoading
                }
              >
                Guardar cambios
              </Button>
            </form>
            <Text
              style={{ cursor: "pointer" }}
              weight={theme.fonts.weight.bold}
              color={theme.colors.white}
              onClick={() => setEditMyAccount(false)}
            >
              Cancelar
            </Text>
          </Col>
        </Col>
      </Card>
      <img
        src={mobile}
        alt="image mobile"
        width={"669px"}
        height={"417px"}
        style={{ position: "absolute", left: "0", bottom: "0", zIndex: -1 }}
      />
      <CopywriteFooter />
    </>
  );
};

EditMyAccount.propTypes = {
  setEditMyAccount: PropTypes.func,
};
