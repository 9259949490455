import React from "react";
import PropTypes from "prop-types";
import { PageWrapperPlatform } from "../styles";
import { theme } from "../../../styles/theme";
import { Div, Text, Row, Col } from "../../../styles/Common";
import { ChevronRightIcon, DataIcon } from "../../../assets/icons";

export const HomePriv = ({ name }) => {
  return (
    <PageWrapperPlatform p="31px 97px 57.23px 98px">
      <Col>
        <Col>
          <Text
            size={theme.fonts.size.h4}
            weight={theme.fonts.weight.semibold}
            color={theme.colors.white}
            mb="10px"
          >
            Hola,{" "}
            <span style={{ color: theme.colors.red }}>
              {name.split(" ")[0].substring(0, 15)}
            </span>{" "}
          </Text>
          <Text
            size={theme.fonts.size.h3}
            weight={theme.fonts.weight.semibold}
            color={theme.colors.white}
            mb="32px"
          >
            Bienvenido a la comunidad Pulpey.
          </Text>
          <Text
            size={theme.fonts.size.h6}
            weight={theme.fonts.weight.semibold}
            color={theme.colors.white}
            mb="32px"
          >
            Para poder ser parte de las actividades que las marcas tiene para
            ti, por favor recuerda llenar las encuestas, con esta información
            podremos enviarte la PulpeyBox más adecuada a tus gustos. Llena las
            encuestas con confianza y sinceridad. Gracias.
          </Text>
        </Col>
        <Row gap="17px">
          <Div
            width="33%"
            height="104.7px"
            radius="24px"
            background={theme.colors.red}
            align="left"
            p="20px 17px 20px 24px"
          >
            <Col justify="center" m="0 15.5px 0 0">
              <Div
                background={theme.colors.red50}
                width="50px"
                height="50px"
                radius="11px"
                align="center"
                justify="center"
              >
                <DataIcon
                  stroke={theme.colors.red}
                  width="24px"
                  height="24px"
                />
              </Div>
            </Col>
            <Col justify="center" width="100%">
              <Text color={theme.colors.white} mb="9px">
                Encuesta 1
              </Text>
              <Text
                weight={theme.fonts.weight.bold}
                color={theme.colors.white}
                size={theme.fonts.size.h6}
              >
                Alimentos y bebidas
              </Text>
            </Col>
            <Col justify="center">
              <ChevronRightIcon
                width="32px"
                height="32px"
                stroke={theme.colors.white}
              />
            </Col>
          </Div>
          <Div
            width="33%"
            height="104.7px"
            radius="24px"
            background={theme.colors.green300}
            align="left"
            p="20px 17px 20px 24px"
          >
            <Col justify="center" m="0 15.5px 0 0">
              <Div
                background={theme.colors.white300}
                width="50px"
                height="50px"
                radius="11px"
                align="center"
                justify="center"
              >
                <DataIcon
                  stroke={theme.colors.green300}
                  width="24px"
                  height="24px"
                />
              </Div>
            </Col>
            <Col justify="center" width="100%">
              <Text color={theme.colors.white} mb="9px">
                Encuesta 2
              </Text>
              <Text
                weight={theme.fonts.weight.bold}
                color={theme.colors.white}
                size={theme.fonts.size.h6}
              >
                Cuidado Personal
              </Text>
            </Col>
            <Col justify="center">
              <ChevronRightIcon
                width="32px"
                height="32px"
                stroke={theme.colors.white}
              />
            </Col>
          </Div>
          <Div
            width="33%"
            height="104.7px"
            radius="24px"
            background={theme.colors.yellowlight}
            align="left"
            p="20px 17px 20px 24px"
          >
            <Col justify="center" m="0 15.5px 0 0">
              <Div
                background={theme.colors.yellow50}
                width="50px"
                height="50px"
                radius="11px"
                align="center"
                justify="center"
              >
                <DataIcon
                  stroke={theme.colors.yellowlight}
                  width="24px"
                  height="24px"
                />
              </Div>
            </Col>
            <Col justify="center" width="100%">
              <Text color={theme.colors.gray300} mb="9px">
                Encuesta 3
              </Text>
              <Text
                weight={theme.fonts.weight.bold}
                color={theme.colors.gray300}
                size={theme.fonts.size.h6}
              >
                Cuidado Personal
              </Text>
            </Col>
            <Col justify="center">
              <ChevronRightIcon
                width="32px"
                height="32px"
                stroke={theme.colors.gray300}
              />
            </Col>
          </Div>
        </Row>
      </Col>
    </PageWrapperPlatform>
  );
};

HomePriv.propTypes = {
  name: PropTypes.string,
};
