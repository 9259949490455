import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Modal } from "../../../components/Modal";
import { Text, Row, Col } from "../../../styles/Common";
import { Radio } from "../../../components/Radio";
import { theme } from "../../../styles/theme";
import { Button } from "../../../components/Button";
import { Input } from "../../../components/Input";
import { Select } from "../../../components/Select";
import { validateResetPassRequest } from "../../../utilities/validations";
import useSendEmailOtp from "../hooks/useSendEmailOtp";
import useSendSmsOtp from "../hooks/useSendSmsOtp";
import useGetPhoneCode from "../../../core/hooks/useGetPhoneCode";
import { setTokenRequested } from "../../../core/store/auth";
import { getLabelById } from "../../../utilities/helpers";

const ModalResetPassword = ({
  showModal,
  handleCloseModal,
  setShowModalOTP,
  isMobile,
}) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validateResetPassRequest),
    defaultValues: {
      ind: 1,
    },
  });
  const dispatch = useDispatch();

  const sendEmailOtp = useSendEmailOtp();
  const sendSmsOtp = useSendSmsOtp();

  const getPhoneCode = useGetPhoneCode();
  const { data, isSuccess } = getPhoneCode;
  const [optionsCodes, setOptionsCodes] = useState([]);
  useEffect(() => {
    if (isSuccess) {
      setOptionsCodes(
        data?.data?.result.map((item) => ({
          label: item.code,
          value: item.id,
        }))
      );
    }
  }, [data, isSuccess]);

  const recoveryMethod = watch("recovery");

  const handleRequestOtp = (data) => {
    const action = recoveryMethod === "phone" ? sendSmsOtp : sendEmailOtp;
    const dataToSend =
      recoveryMethod === "phone"
        ? {
            phoneNumber: `${getLabelById(Number(data.ind), optionsCodes)}${
              data.phone
            }`,
            isPasswordReset: true,
          }
        : { email: data.email, isPasswordReset: true };

    action.reset();
    action.mutate(dataToSend, {
      onSuccess: (res) => {
        dispatch(
          setTokenRequested({
            recoveryMethod: recoveryMethod,
            dataToSend: dataToSend,
            token: res?.data?.result,
            phoneResetPassword: data.phone,
          })
        );
        handleCloseModal();
        setShowModalOTP(true);
      },
      onError: (err) => {
        toast.error(
          err?.response?.data?.title || err?.response?.data || err?.message
        );
      },
    });
  };

  return (
    <Modal
      open={showModal}
      onCancel={handleCloseModal}
      height="auto"
      width={!isMobile ? "611px" : "100%"}
    >
      <Modal.Header
        title={"Recupera tu cuenta"}
        sizeTitle={theme.fonts.size.h1}
        colorTitle={theme.colors.blue500}
        weightTitle={theme.fonts.weight.bold}
        margin={"0 0 5px 0"}
      />
      <Modal.Body>
        <Text size={theme.fonts.size.h6} color={theme.colors.gray200} mb="39px">
          Para proceder con la recuperación de la cuenta, es necesario obtener y
          enviar un código de verificación.
        </Text>
        <Text
          weight={theme.fonts.weight.semibold}
          size={theme.fonts.size.h6}
          color={theme.colors.gray300}
          mb="24px"
        >
          ¿Dónde deseas recibir el código?
        </Text>

        <form
          onSubmit={handleSubmit(handleRequestOtp)}
          style={{
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Controller
            name="recovery"
            control={control}
            render={({ field }) => (
              <Radio.Group
                {...field}
                value={field.value}
                gap="48px"
                error={errors?.recovery}
                helper={errors?.recovery?.message}
              >
                <Radio
                  value="phone"
                  label="Celular"
                  color={theme.colors.gray300}
                />
                <Radio
                  value="email"
                  label="Correo electrónico"
                  color={theme.colors.gray300}
                />
              </Radio.Group>
            )}
          />
          {recoveryMethod === "phone" && (
            <>
              <Text mt="19px" mb="13px" color={theme.colors.gray300}>
                Indica tu número de celular
              </Text>
              <Row gap="15px" align="center" justify="center">
                <Controller
                  name="ind"
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder="+502"
                      color={theme.colors.gray300}
                      options={optionsCodes}
                      border={theme.colors.gray200}
                      width="80px"
                      background={theme.colors.white}
                      error={errors?.ind}
                      helper={errors?.ind?.message}
                      style={{ padding: "0 0" }}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <Input
                      color={theme.colors.gray300}
                      placeholder="Celular"
                      border={theme.colors.gray200}
                      background={theme.colors.white}
                      error={errors?.phone}
                      helper={errors?.phone?.message}
                      onChange={(e) => {
                        const value = e.target.value;
                        const filteredValue = value.replace(/[^0-9]/g, "");
                        field.onChange(filteredValue);
                      }}
                      value={field.value}
                    />
                  )}
                />
              </Row>
            </>
          )}
          {recoveryMethod === "email" && (
            <Col align="center">
              <Text mt="19px" mb="13px" color={theme.colors.gray300}>
                Indica tu correo electrónico
              </Text>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder="Correo electrónico"
                    color={theme.colors.gray300}
                    background={theme.colors.white}
                    width={!isMobile ? "388.5px" : "100%"}
                    border={theme.colors.gray200}
                    error={errors?.email}
                    helper={errors?.email?.message}
                    {...field}
                  />
                )}
              />
            </Col>
          )}
          <Button
            width={"165px"}
            htmlType="submit"
            m="32.22px 0 0 0"
            disabled={!isValid}
            loading={sendEmailOtp.isLoading || sendSmsOtp.isLoading}
          >
            Solicitar código
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalResetPassword;
