import React from "react";
import { PageWrapper } from "./styles";
import { Div, Text, Col, Row } from "../../styles/Common";
import { theme } from "../../styles/theme";
import { Button } from "../../components/Button";
import logo from "../../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
import { CopywriteFooter } from "../../components/CopywriteFooter";
import { PackingIcon } from "../../assets/icons";

export const Insights = () => {
  const navigate = useNavigate();

  const Info = [
    {
      title: "Evaluación de Producto",
      info: "Análisis del rendimiento, uso y aceptación de tu producto según la opinión de tu target.",
    },
    {
      title: "Deep Dive",
      info: "Explora a fondo las áreas de oportunidad de tu marca para potenciar su crecimiento",
    },
    {
      title: "Patrones de Consumo",
      info: "Identifica las características y hábitos de tu consumidor para fortalecer la estrategia de tu marca.",
    },
    {
      title: "Evaluación de Imagen",
      info: "Analiza cómo los consumidores perciben tu marca y optimiza la comunicación visual",
    },
    {
      title: "Testeo de Concepto",
      info: "Presenta nuevas líneas de productos a tus consumidores y evalúa su aceptación.",
    },
  ];

  const panelsHome = [
    {
      title: "Selección de Hogares",
      info: "Se identifican y seleccionan hogares representativos del público objetivo para garantizar la obtención de datos precisos y relevantes.",
    },
    {
      title: "Proceso de Muestreo",
      info: "Los productos se distribuyen a los hogares seleccionados, asegurando una experiencia controlada para obtener feedback detallado.",
    },
    {
      title: "Recopilación de Datos",
      info: "Se recolecta la información proporcionada por los hogares participantes mediante encuestas y herramientas digitales.",
    },
    {
      title: "Análisis de Datos",
      info: "Los datos recopilados son evaluados y procesados para generar insights sobre el rendimiento del producto o la marca",
    },
    {
      title: "Comunicación y Seguimiento",
      info: "Se elaboran reportes con recomendaciones estratégicas para optimizar decisiones de marketing y producto.",
    },
  ];

  return (
    <PageWrapper>
      <Div
        width={"100%"}
        height={"99px"}
        justify="space-between"
        p={"28px 8px"}
        align="center"
        m="0 0 20px 0"
        style={{ backdropFilter: "blur(16px)" }}
      >
        <img
          src={logo}
          alt="logo"
          width={"154.65px"}
          height={"35.33px"}
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        />
        <Div gap="18px" align="center">
          <Button
            variant={"outlined"}
            width={"157px"}
            height={"40px"}
            onClick={() => navigate("/")}
          >
            Inicio
          </Button>
          <Button width={"157px"} height={"40px"} variant={"outlined"}>
            Corporativo
          </Button>
          <Button
            width={"157px"}
            height={"40px"}
            onClick={() => navigate("/login")}
          >
            Login / Registro
          </Button>
        </Div>
      </Div>
      <Div direction="column" m="0 0 40px 0" width="100%">
        <Text
          size={theme.fonts.size.h5}
          color={theme.colors.white}
          weight={theme.fonts.weight.semibold}
          mb="19px"
          style={{ width: "100%" }}
        >
          Somos la plataforma interactiva que conecta las marcas con su público
          objetivo. <br /> <br /> Creamos experiencias innovadoras en los
          hogares de los PulpeyLovers, que permiten obtener insights relevantes
          para el desarrollo de estrategias de marketing alineadas a los
          objetivos comerciales de las marcas. <br /> <br /> Nuestra base nos
          permite segmentar exhaustivamente, haciendo eficiente la Prueba de
          productos y la investigación de mercados a través de nuestro método
          360experience, que consta de 4 ejes:
        </Text>
        <Div direction="column" width="100%" gap="7px">
          <Text color={theme.colors.white} style={{ width: "100%" }}>
            ➢ Experiencia en casa
          </Text>
          <Text color={theme.colors.white} style={{ width: "100%" }}>
            ➢ Publicidad Orgánica
          </Text>
          <Text color={theme.colors.white} style={{ width: "100%" }}>
            ➢ Product Sampling
          </Text>
          <Text color={theme.colors.white} style={{ width: "100%" }}>
            ➢ Zoomins / Insights
          </Text>
        </Div>
      </Div>
      <Div direction="column" m="0 0 68px 0" width="100%">
        <Text
          size={theme.fonts.size.h1i}
          color={theme.colors.white}
          weight={theme.fonts.weight.semibold}
          mb="19px"
        >
          Pulpey Zoomins
        </Text>
        <Text
          size={theme.fonts.size.h4}
          color={theme.colors.white}
          style={{ width: "100%" }}
        >
          Un Zoomin es una herramienta de análisis profunda que permite explorar
          aspectos clave de un producto o marca, brindando insights valiosos
          para la toma de decisiones estratégicas de marketing.
        </Text>
      </Div>

      <Row
        gap="17px"
        style={{
          flexWrap: "wrap",
        }}
        justify="center"
        m="0 0 52px 0"
      >
        {Info.map((item, index) => (
          <Div
            direction="column"
            width="379px"
            height="239px"
            background={theme.colors.whiteBlur}
            style={{ backdropFilter: "blur(30px)" }}
            radius="32px"
            p="26px"
            key={index}
          >
            <Div
              width="53px"
              height="53px"
              background={theme.colors.red}
              radius="50px"
              align="center"
              justify="center"
              m="0 0 16px 0"
            >
              <PackingIcon
                width="32px"
                height="32px"
                stroke={theme.colors.white}
              />
            </Div>
            <Text
              size={theme.fonts.size.h4}
              color={theme.colors.white}
              weight={theme.fonts.weight.semibold}
              mb="19px"
            >
              {item.title}
            </Text>
            <Text color={theme.colors.white}>{item.info}</Text>
          </Div>
        ))}
      </Row>
      <Div direction="column" m="0 0 48px 0" width="100%">
        <Text
          size={theme.fonts.size.h3}
          color={theme.colors.white}
          weight={theme.fonts.weight.semibold}
          mb="19px"
        >
          Home Pulse
        </Text>
        <Text
          size={theme.fonts.size.h4}
          color={theme.colors.white}
          style={{ width: "100%" }}
        >
          Home Pulse es un sistema de muestreo periódico que permite medir KPIs
          clave en múltiples rondas para evaluar el rendimiento de tu marca a lo
          largo del tiempo. A través de la selección de hogares representativos
          y análisis continuo, Home Pulse proporciona insights valiosos para
          mejorar productos y optimizar tu estrategia de marketing
        </Text>
      </Div>
      <Row gap="25px" m="0 0 55px 0" style={{ position: "relative" }}>
        {panelsHome.map((item, index) => (
          <Div
            width="230px"
            height="200px"
            background={theme.colors.blue500}
            radius="16px"
            p="26px 17px"
            direction="column"
            gap="15px"
            key={index}
            align="center"
            m={index % 2 === 0 ? "87px 0 0 0" : "0"}
            style={{ zIndex: 1 }}
          >
            <Text
              size={theme.fonts.size.h4}
              color={theme.colors.white}
              weight={theme.fonts.weight.semibold}
            >
              {item.title}
            </Text>
            <Text
              size={theme.fonts.size.sm}
              color={theme.colors.white}
              align="center"
            >
              {item.info}
            </Text>
          </Div>
        ))}
        <svg
          width="100%"
          height="89.6px"
          viewBox="0 0 1000 100"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: "absolute",
            top: "50%",
            left: "0px",
            transform: "translateY(-50%)",
            zIndex: 0,
          }}
        >
          <path
            d="M10 50
         C 100 7, 200 7, 290 50
         S 480 93, 570 50
         S 760 7, 850 50
         S 1040 93, 1130 50"
            stroke="#FE4F51"
            strokeWidth="2"
            strokeDasharray="10, 10"
            fill="none"
          />
        </svg>
      </Row>
      <Row gap="33px" m="0 0 40px 0">
        <Div
          direction="column"
          width="400px"
          height="210px"
          background={theme.colors.whiteBlur}
          style={{ backdropFilter: "blur(30px)" }}
          radius="32px"
          p="32px 26px"
        >
          <Text
            size={theme.fonts.size.h4}
            color={theme.colors.white}
            weight={theme.fonts.weight.semibold}
            mb="19px"
          >
            Benchmarking:
          </Text>
          <Text size={theme.fonts.size.h6} color={theme.colors.white}>
            Al realizar rondas periódicas, Home Pulse permite establecer puntos
            de referencia que ayudan a las marcas a entender su posicionamiento
            frente a la competencia y dentro del mercado.
          </Text>
        </Div>
        <Div
          direction="column"
          width="400px"
          height="210px"
          background={theme.colors.whiteBlur}
          style={{ backdropFilter: "blur(30px)" }}
          radius="32px"
          p="32px 26px"
        >
          <Text
            size={theme.fonts.size.h4}
            color={theme.colors.white}
            weight={theme.fonts.weight.semibold}
            mb="19px"
          >
            Predicción de Tendencias:
          </Text>
          <Text size={theme.fonts.size.h6} color={theme.colors.white}>
            Con un análisis constante, Home Pulse ayuda a anticipar cambios en
            el comportamiento de los consumidores y detectar oportunidades antes
            que los competidores.
          </Text>
        </Div>
        <Div
          direction="column"
          width="400px"
          height="210px"
          background={theme.colors.whiteBlur}
          style={{ backdropFilter: "blur(30px)" }}
          radius="32px"
          p="32px 26px"
        >
          <Text
            size={theme.fonts.size.h4}
            color={theme.colors.white}
            weight={theme.fonts.weight.semibold}
            mb="19px"
          >
            Optimización en Tiempo Real:
          </Text>
          <Text size={theme.fonts.size.h6} color={theme.colors.white}>
            Gracias a la estructura de medición continua, las marcas pueden
            ajustar sus estrategias en tiempo real, basándose en datos recientes
            y relevantes.
          </Text>
        </Div>
      </Row>
      <Row m="0 0 107px 0">
        <Text
          size={theme.fonts.size.h4}
          color={theme.colors.white}
          style={{ width: "100%" }}
          align="center"
          weight={theme.fonts.weight.bold}
        >
          Home Pulse facilita una retroalimentación constante, convirtiéndose en
          una herramienta fundamental para aquellas marcas que buscan adaptarse
          rápidamente a las dinámicas del mercado.
        </Text>
      </Row>
      <Col align="center" m="0 0 17px 0" gap="10px">
        <Text
          size={theme.fonts.size.h3}
          color={theme.colors.white}
          weight={theme.fonts.weight.semibold}
        >
          ¿Piensas evaluar otras áreas de tu marca o producto?
        </Text>
        <Text size={theme.fonts.size.h4} color={theme.colors.yellowlight}>
          Cuéntanos y te asesoramos
        </Text>
      </Col>
      <Button width={"166px"} onClick={() => navigate("/contactUs")}>
        Contáctanos
      </Button>
      <CopywriteFooter />
    </PageWrapper>
  );
};
