import styled from "styled-components";
import { theme } from "../../styles/theme";
import backImage from "../../assets/images/backgroundHome.png";

export const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  background: transparent url(${backImage}) 0% 0% no-repeat padding-box;
  background-position: center;
  flex-direction: column;
  align-items: center;
  padding: 0 98px 48px 98px;
`;
