import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import PropTypes from "prop-types";
import _ from "lodash";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import { Row, Text, Col } from "../../../styles/Common";
import { theme } from "../../../styles/theme";
import { Button } from "../../../components/Button";
import { Select } from "../../../components/Select";
import { Input } from "../../../components/Input";
import { Radio } from "../../../components/Radio";
import { Tag } from "../../../components/Tag";
import useSaveUserProfile from "../hooks/useSaveUserProfile";
import useGetIncomeRange from "../../../core/hooks/useGetIncomeRange";
import useGetNumberChildren from "../../../core/hooks/useGetNumberChildren";
import useGetPhysicalActivities from "../../../core/hooks/useGetPhysicalActivities";
import useGetPets from "../../../core/hooks/useGetPets";
import useGetHobbies from "../../../core/hooks/useGetHobbies";
import useGetProfessions from "../../../core/hooks/useGetProfessions";
import { getLabelById } from "../../../utilities/helpers";
import ModalSuccess from "./ModalSuccess";
import { Instagram } from "../../../assets/icons";

export const Step3 = ({ formStep3 }) => {
  const queryClient = useQueryClient();
  const saveUserProfile = useSaveUserProfile();
  const { handleSubmit, control, watch, reset, setValue } = useForm({});
  const [showModal, setShowModal] = useState(false);

  const selectedHobbies = watch("hobbies") || [];
  const selectedPets = watch("pets") || [];
  useEffect(() => {
    reset({
      incomeRange: formStep3.incomeRange,
      numberChildren: formStep3.numberChildren,
      physicalActivities: formStep3.physicalActivities,
      pets: formStep3.pets,
      profession: formStep3.profession,
      instagram: formStep3.instagram,
      householdRole: formStep3.householdRole,
      isPregnant: formStep3.isPregnant,
      isInterestedTechnology: formStep3.isInterestedTechnology,
      hobbies: formStep3.hobbies,
    });
  }, [formStep3]);

  const getIncomeRange = useGetIncomeRange();
  const { data: dataIncomeRange, isSuccess: isSuccessIncomeRange } =
    getIncomeRange;
  const [optionsIncomeRange, setOptionsIncomeRange] = useState([]);
  useEffect(() => {
    if (isSuccessIncomeRange) {
      setOptionsIncomeRange(
        dataIncomeRange?.data?.result.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      );
    }
  }, [dataIncomeRange, isSuccessIncomeRange]);

  const getNumberChildren = useGetNumberChildren();
  const { data: dataNumberChildren, isSuccess: isSuccessNumberChildren } =
    getNumberChildren;
  const [optionsNumberChildren, setOptionsNumberChildren] = useState([]);
  useEffect(() => {
    if (isSuccessNumberChildren) {
      setOptionsNumberChildren(
        dataNumberChildren?.data?.result.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      );
    }
  }, [dataNumberChildren, isSuccessNumberChildren]);

  const getPhysicalActivities = useGetPhysicalActivities();
  const {
    data: dataPhysicalActivities,
    isSuccess: isSuccessPhysicalActivities,
  } = getPhysicalActivities;
  const [optionsPhysicalActivities, setOptionsPhysicalActivities] = useState(
    []
  );
  useEffect(() => {
    if (isSuccessPhysicalActivities) {
      setOptionsPhysicalActivities(
        dataPhysicalActivities?.data?.result.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      );
    }
  }, [dataPhysicalActivities, isSuccessPhysicalActivities]);

  const getPets = useGetPets();
  const { data: dataPets, isSuccess: isSuccessPets } = getPets;
  const [optionsPets, setOptionsPets] = useState([]);
  useEffect(() => {
    if (isSuccessPets) {
      setOptionsPets(
        dataPets?.data?.result.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      );
    }
  }, [dataPets, isSuccessPets]);

  const getHobies = useGetHobbies();
  const { data: dataHobbies, isSuccess: isSuccessHobbies } = getHobies;
  const [optionsHobbies, setOptionsHobbies] = useState([]);
  useEffect(() => {
    if (isSuccessHobbies) {
      setOptionsHobbies(
        dataHobbies?.data?.result.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      );
    }
  }, [dataHobbies, isSuccessHobbies]);

  const getProfessions = useGetProfessions();
  const { data: dataProfessions, isSuccess: isSuccessProfessions } =
    getProfessions;
  const [optionsProfessions, setOptionsProfessions] = useState([]);
  useEffect(() => {
    if (isSuccessProfessions) {
      const groupedProfessions = _.groupBy(dataProfessions.data.result, "code");
      const formattedOptions = Object.keys(groupedProfessions).map((code) => ({
        label: code,
        options: groupedProfessions[code].map((item) => ({
          label: item.name,
          value: item.id,
        })),
      }));

      setOptionsProfessions(formattedOptions);
    }
  }, [dataProfessions, isSuccessProfessions]);

  const handleClose = (removedTag, value) => {
    const currentValues = watch(value);
    const newHobbies = currentValues.filter((item) => item !== removedTag);

    setValue(value, newHobbies);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSave = (data) => {
    saveUserProfile.reset();
    saveUserProfile.mutate(
      {
        userId: Number(formStep3.userId),
        maritalStatusId: null,
        genderId: null,
        roleHouseId: null,
        cityId: null,
        address: null,
        stepNumber: 3,
        phoneNumber: null,
        name: null,
        email: null,
        lastName: null,
        birthDate: null,
        phoneNumberCode: null,
        departmentId: null,
        incomeRangeId: data.incomeRange,
        numberChildrenId: data.numberChildren,
        frequencyActivitiesPhysicalId: data.physicalActivities,
        petsId: data.pets ? data.pets.filter((item) => item) : null,
        professionsId: data.profession,
        instagram: data.instagram,
        isBuyManagerHome: data.householdRole,
        isPregnant: data.isPregnant,
        isInterestedTechnology: data.isInterestedTechnology,
        hobbiesId: data.hobbies ? data.hobbies.filter((item) => item) : null,
        instruction: null,
        exactAddress: null,
        latitude: null,
        longitude: null,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["getUserProfile"]);
          setShowModal(true);
        },
        onError: (err) => {
          toast.error(err?.response?.data?.title);
        },
      }
    );
  };

  return (
    <>
      <ModalSuccess showModal={showModal} handleCloseModal={handleCloseModal} />
      <Row m="0 0 20px 0">
        <Text size={theme.fonts.size.h6} color={theme.colors.white}>
          Esta información adicional es completamente opcional, más adelante
          puedes complementarla y/o editarla
        </Text>
      </Row>
      <Row gap="32px" m="0 0 32px 0">
        <Controller
          name="incomeRange"
          control={control}
          render={({ field }) => (
            <Select
              label="Rango de ingresos"
              color={theme.colors.gray200}
              border={theme.colors.white200}
              width="315.95px"
              height="45px"
              background={theme.colors.blue100}
              options={optionsIncomeRange}
              {...field}
            />
          )}
        />
        <Controller
          name="numberChildren"
          control={control}
          render={({ field }) => (
            <Select
              label="Cantidad de hijos"
              color={theme.colors.gray200}
              border={theme.colors.white200}
              width="315.95px"
              height="45px"
              background={theme.colors.blue100}
              options={optionsNumberChildren}
              {...field}
            />
          )}
        />

        <Controller
          name="physicalActivities"
          control={control}
          render={({ field }) => (
            <Select
              label="Frecuencia actividades físicas"
              color={theme.colors.gray200}
              border={theme.colors.white200}
              width="315.95px"
              height="45px"
              background={theme.colors.blue100}
              options={optionsPhysicalActivities}
              {...field}
            />
          )}
        />
      </Row>
      <Row gap="32px" m="0 0 32px 0">
        <Controller
          name="profession"
          control={control}
          render={({ field }) => (
            <Select
              label="Profesión u oficio"
              color={theme.colors.gray200}
              border={theme.colors.white200}
              width="315.95px"
              height="45px"
              background={theme.colors.blue100}
              options={optionsProfessions}
              {...field}
            />
          )}
        />
        <Controller
          name="instagram"
          control={control}
          render={({ field }) => (
            <Input
              label="Perfil de Instagram"
              sizeLabel={theme.fonts.size.default}
              colorLabel={theme.colors.gray200}
              color={theme.colors.gray200}
              border={theme.colors.white200}
              style={{ padding: "5px 12px" }}
              width="316px"
              height="45px"
              background={theme.colors.blue100}
              options={optionsProfessions}
              suffix={
                <Instagram
                  stroke={theme.colors.gray200}
                  width={"24px"}
                  height={"24px"}
                />
              }
              {...field}
            />
          )}
        />
      </Row>
      <Row m="0 0 32px 0" gap="70px">
        <Col gap="4px" width="300px">
          <Text color={theme.colors.gray200}>
            {" "}
            ¿Te interesa la innovación tecnológica?
          </Text>
          <Controller
            name="isInterestedTechnology"
            control={control}
            render={({ field }) => (
              <Radio.Group {...field} value={field.value} gap="48px">
                <Radio
                  value={true}
                  label="Si"
                  background={theme.colors.blue100}
                />
                <Radio
                  value={false}
                  label="No"
                  background={theme.colors.blue100}
                />
              </Radio.Group>
            )}
          />
        </Col>
        <Col gap="10px" width="300px">
          <Text color={theme.colors.gray200}>
            ¿Tomas el rol de las compras del hogar?
          </Text>
          <Controller
            name="householdRole"
            control={control}
            render={({ field }) => (
              <Radio.Group {...field} value={field.value} gap="48px">
                <Radio
                  value={true}
                  label="Si"
                  background={theme.colors.blue100}
                />
                <Radio
                  value={false}
                  label="No"
                  background={theme.colors.blue100}
                />
              </Radio.Group>
            )}
          />
        </Col>
        <Col gap="4px" width="300px">
          <Text color={theme.colors.gray200}>¿Te encuentras embarazada?</Text>
          <Controller
            name="isPregnant"
            control={control}
            render={({ field }) => (
              <Radio.Group {...field} value={field.value} gap="48px">
                <Radio
                  value={true}
                  label="Si"
                  background={theme.colors.blue100}
                />
                <Radio
                  value={false}
                  label="No"
                  background={theme.colors.blue100}
                />
              </Radio.Group>
            )}
          />
        </Col>
      </Row>
      <Row m="0 0 12px 0" gap="32px">
        <Col width="316px">
          <Controller
            name="pets"
            control={control}
            render={({ field }) => (
              <Select
                label="Mascotas"
                mode="multiple"
                color={theme.colors.gray200}
                border={theme.colors.white200}
                width="315.95px"
                height="45px"
                background={theme.colors.blue100}
                options={optionsPets}
                value={null}
                tagRender={() => null}
                {...field}
              />
            )}
          />
          <Row style={{ flexWrap: "wrap" }} m="16px 0 36px 0">
            {selectedPets &&
              selectedPets.length > 0 &&
              selectedPets
                .filter((item) => item)
                .map((item, index) => (
                  <Tag
                    key={index}
                    closable
                    onClose={() => handleClose(item, "pets")}
                  >
                    {getLabelById(item, optionsPets)}
                  </Tag>
                ))}
          </Row>
        </Col>
        <Col>
          <Controller
            name="hobbies"
            control={control}
            render={({ field }) => (
              <Select
                label="Hobbies"
                mode="multiple"
                color={theme.colors.gray200}
                border={theme.colors.white200}
                width="315.95px"
                height="45px"
                background={theme.colors.blue100}
                options={optionsHobbies}
                value={null}
                tagRender={() => null}
                {...field}
              />
            )}
          />
          <Row style={{ flexWrap: "wrap" }} m="16px 0 36px 0">
            {selectedHobbies &&
              selectedHobbies.length > 0 &&
              selectedHobbies
                .filter((item) => item)
                .map((item, index) => (
                  <Tag
                    key={index}
                    closable
                    onClose={() => handleClose(item, "hobbies")}
                  >
                    {getLabelById(item, optionsHobbies)}
                  </Tag>
                ))}
          </Row>
        </Col>
      </Row>

      <form onSubmit={handleSubmit(handleSave)}>
        <Button
          width={"146px"}
          htmlType="submit"
          loading={saveUserProfile.isLoading}
        >
          Finalizar
        </Button>
      </form>
    </>
  );
};

Step3.propTypes = {
  formStep2: PropTypes.object,
  setStepNumber: PropTypes.func,
};
